import React from "react";

const TeamLogo = ({ logo, name }) => {
  return (
    <div style={{ width: 104 }}>
      <img src={logo} alt={name} style={{ width: 32, height: 32 }} />
      <div style={{ marginTop: 5 }}> {name}</div>
    </div>
  );
};

export default TeamLogo;

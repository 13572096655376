function SportTypeLogo({ isSelected, name, logo }) {
  return (
    <div
      style={{
        width: "70px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: "10px",
        marginLeft: "20px",
      }}
    >
      <div
        className={isSelected ? "circletag selected-circle" : "circletag"}
        style={{ backgroundImage: "../../../images/sportsTypes/football.png" }}
      >
        <img
          src={logo}
          alt="football"
          style={{ width: 20, height: 20, marginTop: 25 }}
        ></img>
      </div>
      <div style={{ color: "white" }}>{name}</div>
    </div>
  );
}

export default SportTypeLogo;

export default () => {
  return [
    {
      eventID: 3467449,
      eventData: {
        ev_id: 3467449,
        teams: [
          {
            gender: "M",
            team_id: 1209281,
            team_name: "LJS",
            short_name: "LJS",
          },
          {
            gender: "M",
            team_id: 55,
            team_code: "FC E",
            team_name: "FC Espoo",
            short_name: "FC Espoo",
            team_order: 1,
          },
        ],
        status: "A",
        ev_name: "LJS vs FC Espoo",
        ev_sort: "MT",
        settled: "N",
        xattribs: {
          SCORE_A: "0",
          SCORE_B: "1",
          BG_MATCH_ID: "10904254",
          BR_MATCH_ID: "50529111",
        },
        displayed: "Y",
        disporder: 7,
        hierarchy: {
          type_name: "Finland - Kolmonen",
          class_name: "Escandinavia",
          sb_type_id: 48217,
          sport_code: "FOOT",
          sport_name: "Fútbol",
          sb_class_id: 14334,
          type_disporder: -393,
          class_disporder: -992,
          sport_disporder: -2,
        },
        is_inplay: "Y",
        providers: [
          { feed_id: "BG", feed_type: "EVENT_STATE" },
          { feed_id: "BG", feed_type: "PRICING_PREMATCH" },
          { feed_id: "BG", feed_type: "RESULTING" },
          { feed_id: "BG", feed_type: "PRICING_INPLAY" },
          { feed_id: "BRUO", feed_type: "RESULTING" },
        ],
        MatchState: {
          ev_id: 3467449,
          period: 1,
          time_at: { seconds: 1718384710 },
          play_state: "P",
          sport_code: "FOOT",
          period_desc: "1ª Mitad",
          score_1_pt1: 1,
          score_1_type: "GLS",
          sport_format: "PERIOD",
          clock_running: "Y",
        },
        clock_mode: "F",
        is_virtual: "N",
        start_time: { seconds: 1718384700 },
        num_periods: 2,
        result_conf: "N",
        allow_inplay: "Y",
        period_length: 2700,
        total_markets: 19,
        allow_cash_out: "Y",
        allow_prematch: "Y",
        allow_bb_inplay: "Y",
        allow_bet_build: "Y",
        PeriodMatchScore: {
          ev_id: 3467449,
          period: 1,
          score_1_pt1: 1,
          score_1_type: "GLS",
        },
        extra_time_length: 900,
        num_extra_periods: 2,
        allow_early_payout: "N",
        allow_cash_out_pre_ev: "Y",
      },
      total_markets: 19,
      markets: [
        {
          marketID: 144412116,
          marketData: {
            ev_id: 3467449,
            mkt_id: 144412116,
            status: "A",
            ev_name: "LJS vs FC Espoo",
            settled: "N",
            ew_avail: "N",
            fc_avail: "N",
            mkt_name: "Resultado Final (Tiempo Regular)",
            mkt_sort: "MRES",
            tc_avail: "N",
            displayed: "Y",
            disporder: 10,
            ew_rating: 1,
            providers: [{ feed_id: "BG", feed_type: "PRICING_INPLAY" }],
            equiv_sort: "MRES",
            result_conf: "N",
            inplay_avail: "Y",
            allow_cash_out: "Y",
            allow_bb_inplay: "Y",
            allow_bet_build: "Y",
            has_boosted_child: "N",
            allow_cash_out_pre_ev: "Y",
            available_price_types: [{ price_type: "LP" }],
            bb_hcap_half_pts_only: "N",
          },
          defaultmktsort: true,
          selections: [
            {
              selectionID: 468924746,
              selectionData: {
                mkt_id: 144412116,
                prices: [
                  {
                    mkt_id: 144412116,
                    bet_ref: "NDY4OTI0NzQ2OjIwLzE6OjA",
                    prc_den: 1,
                    prc_num: 20,
                    seln_id: 468924746,
                    price_id: 446885213,
                    prob_100: 0.022007,
                    price_type: "LP",
                    prc_decimal: "21.00",
                    prc_american: "+2000",
                    prc_fractional: "20/1",
                  },
                ],
                status: "A",
                seln_id: 468924746,
                settled: "N",
                displayed: "Y",
                disporder: 0,
                ptcpnt_id: 1209281,
                seln_name: "LJS",
                seln_sort: "H",
                ptcpnt_type: "T",
                result_conf: "N",
                price_history: [
                  {
                    mkt_id: 144412116,
                    bet_ref: "NDY4OTI0NzQ2OjIwLzE6OjA",
                    prc_den: 1,
                    prc_num: 20,
                    seln_id: 468924746,
                    price_id: 446885213,
                    prob_100: 0.024242,
                    price_type: "LP",
                    prc_decimal: "21.00",
                    prc_american: "+2000",
                    prc_fractional: "20/1",
                  },
                  {
                    mkt_id: 144412116,
                    bet_ref: "NDY4OTI0NzQ2OjIwLzE6OjA",
                    prc_den: 1,
                    prc_num: 20,
                    seln_id: 468924746,
                    price_id: 446885213,
                    prob_100: 0.023015,
                    price_type: "LP",
                    prc_decimal: "21.00",
                    prc_american: "+2000",
                    prc_fractional: "20/1",
                  },
                  {
                    mkt_id: 144412116,
                    bet_ref: "NDY4OTI0NzQ2OjIwLzE6OjA",
                    prc_den: 1,
                    prc_num: 20,
                    seln_id: 468924746,
                    price_id: 446885213,
                    prob_100: 0.025057,
                    price_type: "LP",
                    prc_decimal: "21.00",
                    prc_american: "+2000",
                    prc_fractional: "20/1",
                  },
                ],
              },
            },
            {
              selectionID: 468924747,
              selectionData: {
                mkt_id: 144412116,
                prices: [
                  {
                    mkt_id: 144412116,
                    bet_ref: "NDY4OTI0NzQ3OjYvMTo6MA",
                    prc_den: 1,
                    prc_num: 6,
                    seln_id: 468924747,
                    price_id: 446885214,
                    prob_100: 0.084446,
                    price_type: "LP",
                    prc_decimal: "7.00",
                    prc_american: "+600",
                    prc_fractional: "6/1",
                  },
                ],
                status: "A",
                seln_id: 468924747,
                settled: "N",
                displayed: "Y",
                disporder: 1,
                seln_name: "Empate",
                seln_sort: "D",
                result_conf: "N",
                price_history: [
                  {
                    mkt_id: 144412116,
                    bet_ref: "NDY4OTI0NzQ3OjYvMTo6MA",
                    prc_den: 1,
                    prc_num: 6,
                    seln_id: 468924747,
                    price_id: 446885214,
                    prob_100: 0.085591,
                    price_type: "LP",
                    prc_decimal: "7.00",
                    prc_american: "+600",
                    prc_fractional: "6/1",
                  },
                  {
                    mkt_id: 144412116,
                    bet_ref: "NDY4OTI0NzQ3OjYvMTo6MA",
                    prc_den: 1,
                    prc_num: 6,
                    seln_id: 468924747,
                    price_id: 446885214,
                    prob_100: 0.084198,
                    price_type: "LP",
                    prc_decimal: "7.00",
                    prc_american: "+600",
                    prc_fractional: "6/1",
                  },
                  {
                    mkt_id: 144412116,
                    bet_ref: "NDY4OTI0NzQ3OjYvMTo6MA",
                    prc_den: 1,
                    prc_num: 6,
                    seln_id: 468924747,
                    price_id: 446885214,
                    prob_100: 0.087968,
                    price_type: "LP",
                    prc_decimal: "7.00",
                    prc_american: "+600",
                    prc_fractional: "6/1",
                  },
                ],
              },
            },
            {
              selectionID: 468924748,
              selectionData: {
                mkt_id: 144412116,
                prices: [
                  {
                    mkt_id: 144412116,
                    bet_ref: "NDY4OTI0NzQ4OjEvMTQ6OjA",
                    prc_den: 14,
                    prc_num: 1,
                    seln_id: 468924748,
                    price_id: 446885215,
                    prob_100: 0.893547,
                    price_type: "LP",
                    prc_decimal: "1.071",
                    prc_american: "-1400",
                    prc_fractional: "1/14",
                  },
                ],
                status: "A",
                seln_id: 468924748,
                settled: "N",
                displayed: "Y",
                disporder: 2,
                ptcpnt_id: 55,
                seln_name: "FC Espoo",
                seln_sort: "A",
                ptcpnt_type: "T",
                result_conf: "N",
                price_history: [
                  {
                    mkt_id: 144412116,
                    bet_ref: "NDY4OTI0NzQ4OjEvMTI6OjA",
                    prc_den: 12,
                    prc_num: 1,
                    seln_id: 468924748,
                    price_id: 446885215,
                    prob_100: 0.890168,
                    price_type: "LP",
                    prc_decimal: "1.083",
                    prc_american: "-1200",
                    prc_fractional: "1/12",
                  },
                  {
                    mkt_id: 144412116,
                    bet_ref: "NDY4OTI0NzQ4OjEvMTQ6OjA",
                    prc_den: 14,
                    prc_num: 1,
                    seln_id: 468924748,
                    price_id: 446885215,
                    prob_100: 0.892787,
                    price_type: "LP",
                    prc_decimal: "1.071",
                    prc_american: "-1400",
                    prc_fractional: "1/14",
                  },
                  {
                    mkt_id: 144412116,
                    bet_ref: "NDY4OTI0NzQ4OjEvMTI6OjA",
                    prc_den: 12,
                    prc_num: 1,
                    seln_id: 468924748,
                    price_id: 446885215,
                    prob_100: 0.886976,
                    price_type: "LP",
                    prc_decimal: "1.083",
                    prc_american: "-1200",
                    prc_fractional: "1/12",
                  },
                ],
              },
            },
          ],
          dividends: null,
        },
      ],
      eventMatch: { matchincidents: null, matchstats: null },
    },
    {
      eventID: 3387171,
      eventData: {
        ev_id: 3387171,
        teams: [
          {
            gender: "M",
            team_id: 4164,
            team_code: "Rca",
            team_name: "Raja CA",
            short_name: "Raja CA",
            team_order: 1,
          },
          {
            gender: "M",
            team_id: 189891,
            team_code: "MOUL",
            team_name: "Mouloudia Oujda",
            short_name: "Mouloudia Oujda",
          },
        ],
        status: "A",
        ev_name: "Mouloudia Oujda vs Raja CA",
        ev_sort: "MT",
        settled: "N",
        xattribs: {
          SCORE_A: "0",
          SCORE_B: "1",
          BG_MATCH_ID: "11104351",
          BR_MATCH_ID: "50539599",
          STATSCORE_ID: "5499606",
          STATSCORE_LSP: "Y",
          STATSCORE_PMC: "Y",
        },
        displayed: "Y",
        disporder: 6,
        hierarchy: {
          type_name: "Marruecos - Botola Pro",
          class_name: "Africa",
          sb_type_id: 29740,
          sport_code: "FOOT",
          sport_name: "Fútbol",
          sb_class_id: 14454,
          type_disporder: -135,
          class_disporder: -991,
          sport_disporder: -2,
        },
        is_inplay: "Y",
        providers: [
          { feed_id: "BG", feed_type: "EVENT_STATE" },
          { feed_id: "BRUO", feed_type: "PRICING_PREMATCH" },
          { feed_id: "BG", feed_type: "PRICING_INPLAY" },
        ],
        MatchState: {
          ev_id: 3387171,
          period: 1,
          time_at: { seconds: 1718384621 },
          play_state: "P",
          sport_code: "FOOT",
          period_desc: "1ª Mitad",
          score_1_pt1: 1,
          score_1_type: "GLS",
          sport_format: "PERIOD",
          clock_running: "Y",
        },
        clock_mode: "F",
        is_virtual: "N",
        start_time: { seconds: 1718384400 },
        num_periods: 2,
        result_conf: "N",
        allow_inplay: "Y",
        period_length: 2700,
        total_markets: 34,
        allow_cash_out: "Y",
        allow_prematch: "Y",
        allow_bb_inplay: "Y",
        allow_bet_build: "Y",
        PeriodMatchScore: {
          ev_id: 3387171,
          period: 1,
          score_1_pt1: 1,
          score_1_type: "GLS",
        },
        extra_time_length: 900,
        num_extra_periods: 2,
        allow_early_payout: "N",
        allow_cash_out_pre_ev: "Y",
      },
      total_markets: 34,
      markets: [
        {
          marketID: 141442745,
          marketData: {
            ev_id: 3387171,
            mkt_id: 141442745,
            status: "A",
            ev_name: "Mouloudia Oujda vs Raja CA",
            settled: "N",
            ew_avail: "N",
            fc_avail: "N",
            mkt_name: "Resultado Final (Tiempo Regular)",
            mkt_sort: "MRES",
            tc_avail: "N",
            displayed: "Y",
            disporder: 10,
            ew_rating: 1,
            providers: [{ feed_id: "BG", feed_type: "PRICING_INPLAY" }],
            equiv_sort: "MRES",
            result_conf: "N",
            inplay_avail: "Y",
            allow_cash_out: "Y",
            allow_bb_inplay: "Y",
            allow_bet_build: "Y",
            has_boosted_child: "N",
            allow_cash_out_pre_ev: "Y",
            available_price_types: [{ price_type: "LP" }],
            bb_hcap_half_pts_only: "N",
          },
          defaultmktsort: true,
          selections: [
            {
              selectionID: 459144584,
              selectionData: {
                mkt_id: 141442745,
                prices: [
                  {
                    mkt_id: 141442745,
                    bet_ref: "NDU5MTQ0NTg0OjEvMTY6OjA",
                    prc_den: 16,
                    prc_num: 1,
                    seln_id: 459144584,
                    price_id: 446735993,
                    prob_100: 0.911552,
                    price_type: "LP",
                    prc_decimal: "1.062",
                    prc_american: "-1600",
                    prc_fractional: "1/16",
                  },
                ],
                status: "A",
                seln_id: 459144584,
                settled: "N",
                displayed: "Y",
                disporder: 5,
                ptcpnt_id: 4164,
                seln_name: "Raja CA",
                seln_sort: "A",
                ptcpnt_type: "T",
                result_conf: "N",
                price_history: [
                  {
                    mkt_id: 141442745,
                    bet_ref: "NDU5MTQ0NTg0OjEvMTY6OjA",
                    prc_den: 16,
                    prc_num: 1,
                    seln_id: 459144584,
                    price_id: 446735993,
                    prob_100: 0.907476,
                    price_type: "LP",
                    prc_decimal: "1.062",
                    prc_american: "-1600",
                    prc_fractional: "1/16",
                  },
                  {
                    mkt_id: 141442745,
                    bet_ref: "NDU5MTQ0NTg0OjEvMTY6OjA",
                    prc_den: 16,
                    prc_num: 1,
                    seln_id: 459144584,
                    price_id: 446735993,
                    prob_100: 0.911651,
                    price_type: "LP",
                    prc_decimal: "1.062",
                    prc_american: "-1600",
                    prc_fractional: "1/16",
                  },
                  {
                    mkt_id: 141442745,
                    bet_ref: "NDU5MTQ0NTg0OjEvMTY6OjA",
                    prc_den: 16,
                    prc_num: 1,
                    seln_id: 459144584,
                    price_id: 446735993,
                    prob_100: 0.905272,
                    price_type: "LP",
                    prc_decimal: "1.062",
                    prc_american: "-1600",
                    prc_fractional: "1/16",
                  },
                ],
              },
            },
            {
              selectionID: 459144585,
              selectionData: {
                mkt_id: 141442745,
                prices: [
                  {
                    mkt_id: 141442745,
                    bet_ref: "NDU5MTQ0NTg1OjMwLzE6OjA",
                    prc_den: 1,
                    prc_num: 30,
                    seln_id: 459144585,
                    price_id: 446735994,
                    prob_100: 0.014813,
                    price_type: "LP",
                    prc_decimal: "31.00",
                    prc_american: "+3000",
                    prc_fractional: "30/1",
                  },
                ],
                status: "A",
                seln_id: 459144585,
                settled: "N",
                displayed: "Y",
                disporder: 1,
                ptcpnt_id: 189891,
                seln_name: "Mouloudia Oujda",
                seln_sort: "H",
                ptcpnt_type: "T",
                result_conf: "N",
                price_history: [
                  {
                    mkt_id: 141442745,
                    bet_ref: "NDU5MTQ0NTg1OjMwLzE6OjA",
                    prc_den: 1,
                    prc_num: 30,
                    seln_id: 459144585,
                    price_id: 446735994,
                    prob_100: 0.016377,
                    price_type: "LP",
                    prc_decimal: "31.00",
                    prc_american: "+3000",
                    prc_fractional: "30/1",
                  },
                  {
                    mkt_id: 141442745,
                    bet_ref: "NDU5MTQ0NTg1OjMwLzE6OjA",
                    prc_den: 1,
                    prc_num: 30,
                    seln_id: 459144585,
                    price_id: 446735994,
                    prob_100: 0.014777,
                    price_type: "LP",
                    prc_decimal: "31.00",
                    prc_american: "+3000",
                    prc_fractional: "30/1",
                  },
                  {
                    mkt_id: 141442745,
                    bet_ref: "NDU5MTQ0NTg1OjI4LzE6OjA",
                    prc_den: 1,
                    prc_num: 28,
                    seln_id: 459144585,
                    price_id: 446735994,
                    prob_100: 0.017224,
                    price_type: "LP",
                    prc_decimal: "29.00",
                    prc_american: "+2000",
                    prc_fractional: "20/1",
                  },
                ],
              },
            },
            {
              selectionID: 459144586,
              selectionData: {
                mkt_id: 141442745,
                prices: [
                  {
                    mkt_id: 141442745,
                    bet_ref: "NDU5MTQ0NTg2OjcvMTo6MA",
                    prc_den: 1,
                    prc_num: 7,
                    seln_id: 459144586,
                    price_id: 446735995,
                    prob_100: 0.073635,
                    price_type: "LP",
                    prc_decimal: "8.00",
                    prc_american: "+700",
                    prc_fractional: "7/1",
                  },
                ],
                status: "A",
                seln_id: 459144586,
                settled: "N",
                displayed: "Y",
                disporder: 3,
                seln_name: "Empate",
                seln_sort: "D",
                result_conf: "N",
                price_history: [
                  {
                    mkt_id: 141442745,
                    bet_ref: "NDU5MTQ0NTg2OjcvMTo6MA",
                    prc_den: 1,
                    prc_num: 7,
                    seln_id: 459144586,
                    price_id: 446735995,
                    prob_100: 0.076148,
                    price_type: "LP",
                    prc_decimal: "8.00",
                    prc_american: "+700",
                    prc_fractional: "7/1",
                  },
                  {
                    mkt_id: 141442745,
                    bet_ref: "NDU5MTQ0NTg2OjcvMTo6MA",
                    prc_den: 1,
                    prc_num: 7,
                    seln_id: 459144586,
                    price_id: 446735995,
                    prob_100: 0.073572,
                    price_type: "LP",
                    prc_decimal: "8.00",
                    prc_american: "+700",
                    prc_fractional: "7/1",
                  },
                  {
                    mkt_id: 141442745,
                    bet_ref: "NDU5MTQ0NTg2OjcvMTo6MA",
                    prc_den: 1,
                    prc_num: 7,
                    seln_id: 459144586,
                    price_id: 446735995,
                    prob_100: 0.077504,
                    price_type: "LP",
                    prc_decimal: "8.00",
                    prc_american: "+700",
                    prc_fractional: "7/1",
                  },
                ],
              },
            },
          ],
          dividends: null,
        },
      ],
      eventMatch: { matchincidents: null, matchstats: null },
    },
    {
      eventID: 3442831,
      eventData: {
        ev_id: 3442831,
        teams: [
          {
            gender: "M",
            team_id: 7183,
            team_code: "SK H",
            team_name: "SK Hranice",
            short_name: "SK Hranice",
          },
          {
            gender: "M",
            team_id: 176750,
            team_code: "SKUN",
            team_name: "SK Unicov",
            short_name: "SK Unicov",
            team_order: 1,
          },
        ],
        status: "A",
        ev_name: "SK Hranice vs SK Unicov",
        ev_sort: "MT",
        settled: "N",
        xattribs: {
          SCORE_A: "3",
          SCORE_B: "1",
          BG_MATCH_ID: "10643479",
          BR_MATCH_ID: "45182934",
          STATSCORE_ID: "5483351",
          STATSCORE_LSP: "Y",
          STATSCORE_PMC: "Y",
        },
        displayed: "Y",
        disporder: 7,
        hierarchy: {
          type_name: "República Checa - 3.Liga",
          class_name: "Europe",
          sb_type_id: 19810,
          sport_code: "FOOT",
          sport_name: "Fútbol",
          sb_class_id: 12434,
          type_disporder: -408,
          class_disporder: -994,
          sport_disporder: -2,
        },
        is_inplay: "Y",
        providers: [
          { feed_id: "BRLS", feed_type: "EVENT_STATE" },
          { feed_id: "BG", feed_type: "RESULTING" },
          { feed_id: "BRUO", feed_type: "RESULTING" },
          { feed_id: "BRUO", feed_type: "PRICING_INPLAY" },
        ],
        MatchState: {
          ev_id: 3442831,
          period: 1,
          time_at: { seconds: 1718384433 },
          play_state: "P",
          sport_code: "FOOT",
          period_desc: "1ª Mitad",
          score_1_pt0: 3,
          score_1_pt1: 1,
          score_1_type: "GLS",
          sport_format: "PERIOD",
          clock_running: "Y",
        },
        clock_mode: "F",
        is_virtual: "N",
        start_time: { seconds: 1718384400 },
        num_periods: 2,
        result_conf: "N",
        allow_inplay: "Y",
        period_length: 2700,
        total_markets: 15,
        allow_cash_out: "Y",
        allow_prematch: "Y",
        allow_bb_inplay: "Y",
        allow_bet_build: "Y",
        PeriodMatchScore: {
          ev_id: 3442831,
          period: 1,
          score_1_pt0: 3,
          score_1_pt1: 1,
          score_1_type: "GLS",
        },
        extra_time_length: 900,
        num_extra_periods: 2,
        allow_early_payout: "N",
        allow_cash_out_pre_ev: "Y",
      },
      total_markets: 15,
      markets: [
        {
          marketID: 143487044,
          marketData: {
            ev_id: 3442831,
            mkt_id: 143487044,
            status: "A",
            ev_name: "SK Hranice vs SK Unicov",
            settled: "N",
            ew_avail: "N",
            fc_avail: "N",
            mkt_name: "Resultado Final (Tiempo Regular)",
            mkt_sort: "MRES",
            tc_avail: "N",
            displayed: "Y",
            disporder: 10,
            ew_rating: 1,
            providers: [{ feed_id: "BRUO", feed_type: "PRICING_INPLAY" }],
            equiv_sort: "MRES",
            result_conf: "N",
            inplay_avail: "Y",
            allow_cash_out: "Y",
            allow_bb_inplay: "Y",
            allow_bet_build: "Y",
            has_boosted_child: "N",
            allow_cash_out_pre_ev: "Y",
            available_price_types: [{ price_type: "LP" }],
            bb_hcap_half_pts_only: "N",
          },
          defaultmktsort: true,
          selections: [
            {
              selectionID: 465861448,
              selectionData: {
                mkt_id: 143487044,
                prices: [
                  {
                    mkt_id: 143487044,
                    bet_ref: "NDY1ODYxNDQ4OjcvMjA6OjA",
                    prc_den: 20,
                    prc_num: 7,
                    seln_id: 465861448,
                    price_id: 448160330,
                    prob_100: 0.703591,
                    price_type: "LP",
                    prc_decimal: "1.35",
                    prc_american: "-286",
                    prc_fractional: "7/20",
                  },
                ],
                status: "A",
                seln_id: 465861448,
                settled: "N",
                displayed: "Y",
                disporder: 0,
                ptcpnt_id: 7183,
                seln_name: "SK Hranice",
                seln_sort: "H",
                ptcpnt_type: "T",
                result_conf: "N",
                price_history: [
                  {
                    mkt_id: 143487044,
                    bet_ref: "NDY1ODYxNDQ4OjIvNTo6MA",
                    prc_den: 5,
                    prc_num: 2,
                    seln_id: 465861448,
                    price_id: 448160330,
                    prob_100: 0.679458,
                    price_type: "LP",
                    prc_decimal: "1.40",
                    prc_american: "-250",
                    prc_fractional: "2/5",
                  },
                  {
                    mkt_id: 143487044,
                    bet_ref: "NDY1ODYxNDQ4OjIvNTo6MA",
                    prc_den: 5,
                    prc_num: 2,
                    seln_id: 465861448,
                    price_id: 448160330,
                    prob_100: 0.681276,
                    price_type: "LP",
                    prc_decimal: "1.40",
                    prc_american: "-250",
                    prc_fractional: "2/5",
                  },
                  {
                    mkt_id: 143487044,
                    bet_ref: "NDY1ODYxNDQ4OjQvMTE6OjA",
                    prc_den: 11,
                    prc_num: 4,
                    seln_id: 465861448,
                    price_id: 448160330,
                    prob_100: 0.682098,
                    price_type: "LP",
                    prc_decimal: "1.364",
                    prc_american: "-275",
                    prc_fractional: "4/11",
                  },
                ],
              },
            },
            {
              selectionID: 465861450,
              selectionData: {
                mkt_id: 143487044,
                prices: [
                  {
                    mkt_id: 143487044,
                    bet_ref: "NDY1ODYxNDUwOjUvMTo6MA",
                    prc_den: 1,
                    prc_num: 5,
                    seln_id: 465861450,
                    price_id: 448160332,
                    prob_100: 0.129676,
                    price_type: "LP",
                    prc_decimal: "6.00",
                    prc_american: "+500",
                    prc_fractional: "5/1",
                  },
                ],
                status: "A",
                seln_id: 465861450,
                settled: "N",
                displayed: "Y",
                disporder: 2,
                ptcpnt_id: 176750,
                seln_name: "SK Unicov",
                seln_sort: "A",
                ptcpnt_type: "T",
                result_conf: "N",
                price_history: [
                  {
                    mkt_id: 143487044,
                    bet_ref: "NDY1ODYxNDUwOjkvMjo6MA",
                    prc_den: 2,
                    prc_num: 9,
                    seln_id: 465861450,
                    price_id: 448160332,
                    prob_100: 0.144551,
                    price_type: "LP",
                    prc_decimal: "5.50",
                    prc_american: "+450",
                    prc_fractional: "9/2",
                  },
                  {
                    mkt_id: 143487044,
                    bet_ref: "NDY1ODYxNDUwOjkvMjo6MA",
                    prc_den: 2,
                    prc_num: 9,
                    seln_id: 465861450,
                    price_id: 448160332,
                    prob_100: 0.143101,
                    price_type: "LP",
                    prc_decimal: "5.50",
                    prc_american: "+450",
                    prc_fractional: "9/2",
                  },
                  {
                    mkt_id: 143487044,
                    bet_ref: "NDY1ODYxNDUwOjE5LzQ6OjA",
                    prc_den: 4,
                    prc_num: 19,
                    seln_id: 465861450,
                    price_id: 448160332,
                    prob_100: 0.142447,
                    price_type: "LP",
                    prc_decimal: "5.75",
                    prc_american: "+475",
                    prc_fractional: "19/4",
                  },
                ],
              },
            },
            {
              selectionID: 465861449,
              selectionData: {
                mkt_id: 143487044,
                prices: [
                  {
                    mkt_id: 143487044,
                    bet_ref: "NDY1ODYxNDQ5OjE1LzQ6OjA",
                    prc_den: 4,
                    prc_num: 15,
                    seln_id: 465861449,
                    price_id: 448160331,
                    prob_100: 0.166733,
                    price_type: "LP",
                    prc_decimal: "4.75",
                    prc_american: "+375",
                    prc_fractional: "15/4",
                  },
                ],
                status: "A",
                seln_id: 465861449,
                settled: "N",
                displayed: "Y",
                disporder: 1,
                seln_name: "Empate",
                seln_sort: "D",
                result_conf: "N",
                price_history: [
                  {
                    mkt_id: 143487044,
                    bet_ref: "NDY1ODYxNDQ5OjcvMjo6MA",
                    prc_den: 2,
                    prc_num: 7,
                    seln_id: 465861449,
                    price_id: 448160331,
                    prob_100: 0.177758,
                    price_type: "LP",
                    prc_decimal: "4.50",
                    prc_american: "+350",
                    prc_fractional: "7/2",
                  },
                  {
                    mkt_id: 143487044,
                    bet_ref: "NDY1ODYxNDQ5OjE1LzQ6OjA",
                    prc_den: 4,
                    prc_num: 15,
                    seln_id: 465861449,
                    price_id: 448160331,
                    prob_100: 0.166716,
                    price_type: "LP",
                    prc_decimal: "4.75",
                    prc_american: "+375",
                    prc_fractional: "15/4",
                  },
                  {
                    mkt_id: 143487044,
                    bet_ref: "NDY1ODYxNDQ5OjcvMjo6MA",
                    prc_den: 2,
                    prc_num: 7,
                    seln_id: 465861449,
                    price_id: 448160331,
                    prob_100: 0.175991,
                    price_type: "LP",
                    prc_decimal: "4.50",
                    prc_american: "+350",
                    prc_fractional: "7/2",
                  },
                ],
              },
            },
          ],
          dividends: null,
        },
      ],
      eventMatch: { matchincidents: null, matchstats: null },
    },
    {
      eventID: 3466630,
      eventData: {
        ev_id: 3466630,
        teams: [
          {
            gender: "M",
            team_id: 812356,
            team_name: "TJ Slavoj Myto",
            short_name: "TJ Slavoj Myto",
          },
          {
            gender: "M",
            team_id: 173967,
            team_code: "RAPI",
            team_name: "Rapid Plzen",
            short_name: "Rapid Plzen",
            team_order: 1,
          },
        ],
        status: "A",
        ev_name: "TJ Slavoj Myto vs Rapid Plzen",
        ev_sort: "MT",
        settled: "N",
        xattribs: { SCORE_A: "2", SCORE_B: "3", BR_MATCH_ID: "50648647" },
        displayed: "Y",
        disporder: 7,
        hierarchy: {
          type_name: "Czech Republic - Krajsky Prebor",
          class_name: "Europe",
          sb_type_id: 47968,
          sport_code: "FOOT",
          sport_name: "Fútbol",
          sb_class_id: 12434,
          type_disporder: -408,
          class_disporder: -994,
          sport_disporder: -2,
        },
        is_inplay: "Y",
        providers: [
          { feed_id: "BRLS", feed_type: "EVENT_STATE" },
          { feed_id: "BRUO", feed_type: "RESULTING" },
          { feed_id: "BRUO", feed_type: "PRICING_INPLAY" },
        ],
        MatchState: {
          secs: 2700,
          ev_id: 3466630,
          period: 2,
          time_at: { seconds: 1718384730 },
          match_secs: 2700,
          play_state: "P",
          sport_code: "FOOT",
          period_desc: "2ª Mitad",
          score_1_pt0: 2,
          score_1_pt1: 3,
          score_1_type: "GLS",
          sport_format: "PERIOD",
          clock_running: "Y",
        },
        clock_mode: "F",
        is_virtual: "N",
        start_time: { seconds: 1718380800 },
        num_periods: 2,
        result_conf: "N",
        allow_inplay: "Y",
        period_length: 2700,
        total_markets: 8,
        allow_cash_out: "Y",
        allow_prematch: "Y",
        allow_bb_inplay: "Y",
        allow_bet_build: "Y",
        PeriodMatchScore: {
          ev_id: 3466630,
          period: 2,
          score_1_pt0: 2,
          score_1_pt1: 1,
          score_1_type: "GLS",
        },
        extra_time_length: 900,
        num_extra_periods: 2,
        allow_early_payout: "N",
        allow_cash_out_pre_ev: "Y",
      },
      total_markets: 8,
      markets: [
        {
          marketID: 144381199,
          marketData: {
            allow_bb_inplay: "Y",
            allow_bet_build: "Y",
            allow_cash_out: "Y",
            allow_cash_out_pre_ev: "Y",
            available_price_types: [{ price_type: "LP" }],
            bb_hcap_half_pts_only: "N",
            displayed: "Y",
            disporder: 10,
            equiv_sort: "MRES",
            ev_id: 3466630,
            ev_name: "TJ Slavoj Myto vs Rapid Plzen",
            ew_avail: "N",
            ew_rating: 1,
            fc_avail: "N",
            has_boosted_child: "N",
            inplay_avail: "Y",
            mkt_id: 144381199,
            mkt_name: "Resultado Final (Tiempo Regular)",
            mkt_sort: "MRES",
            providers: [{ feed_id: "BRUO", feed_type: "PRICING_INPLAY" }],
            result_conf: "N",
            settled: "N",
            status: "A",
            tc_avail: "N",
          },
          defaultmktsort: true,
          selections: [
            {
              selectionID: 468826873,
              selectionData: {
                mkt_id: 144381199,
                prices: [
                  {
                    mkt_id: 144381199,
                    bet_ref: "NDY4ODI2ODczOjEvNjo6MA",
                    prc_den: 6,
                    prc_num: 1,
                    seln_id: 468826873,
                    price_id: 448751189,
                    prob_100: 0.815965,
                    price_type: "LP",
                    prc_decimal: "1.167",
                    prc_american: "-600",
                    prc_fractional: "1/6",
                  },
                ],
                status: "A",
                seln_id: 468826873,
                settled: "N",
                displayed: "Y",
                disporder: 2,
                ptcpnt_id: 173967,
                seln_name: "Rapid Plzen",
                seln_sort: "A",
                ptcpnt_type: "T",
                result_conf: "N",
                price_history: [
                  {
                    mkt_id: 144381199,
                    bet_ref: "NDY4ODI2ODczOjEvNjo6MA",
                    prc_den: 6,
                    prc_num: 1,
                    seln_id: 468826873,
                    price_id: 448751189,
                    prob_100: 0.812102,
                    price_type: "LP",
                    prc_decimal: "1.167",
                    prc_american: "-600",
                    prc_fractional: "1/6",
                  },
                  {
                    mkt_id: 144381199,
                    bet_ref: "NDY4ODI2ODczOjEvNjo6MA",
                    prc_den: 6,
                    prc_num: 1,
                    seln_id: 468826873,
                    price_id: 448751189,
                    prob_100: 0.816523,
                    price_type: "LP",
                    prc_decimal: "1.167",
                    prc_american: "-600",
                    prc_fractional: "1/6",
                  },
                  {
                    mkt_id: 144381199,
                    bet_ref: "NDY4ODI2ODczOjEvNjo6MA",
                    prc_den: 6,
                    prc_num: 1,
                    seln_id: 468826873,
                    price_id: 448751189,
                    prob_100: 0.817952,
                    price_type: "LP",
                    prc_decimal: "1.167",
                    prc_american: "-600",
                    prc_fractional: "1/6",
                  },
                ],
              },
            },
            {
              selectionID: 468826872,
              selectionData: {
                mkt_id: 144381199,
                prices: [
                  {
                    mkt_id: 144381199,
                    bet_ref: "NDY4ODI2ODcyOjE1LzQ6OjA",
                    prc_den: 4,
                    prc_num: 15,
                    seln_id: 468826872,
                    price_id: 448751188,
                    prob_100: 0.153887,
                    price_type: "LP",
                    prc_decimal: "4.75",
                    prc_american: "+375",
                    prc_fractional: "15/4",
                  },
                ],
                status: "A",
                seln_id: 468826872,
                settled: "N",
                displayed: "Y",
                disporder: 1,
                seln_name: "Empate",
                seln_sort: "D",
                result_conf: "N",
                price_history: [
                  {
                    mkt_id: 144381199,
                    bet_ref: "NDY4ODI2ODcyOjE1LzQ6OjA",
                    prc_den: 4,
                    prc_num: 15,
                    seln_id: 468826872,
                    price_id: 448751188,
                    prob_100: 0.158366,
                    price_type: "LP",
                    prc_decimal: "4.75",
                    prc_american: "+375",
                    prc_fractional: "15/4",
                  },
                  {
                    mkt_id: 144381199,
                    bet_ref: "NDY4ODI2ODcyOjE1LzQ6OjA",
                    prc_den: 4,
                    prc_num: 15,
                    seln_id: 468826872,
                    price_id: 448751188,
                    prob_100: 0.155444,
                    price_type: "LP",
                    prc_decimal: "4.75",
                    prc_american: "+375",
                    prc_fractional: "15/4",
                  },
                  {
                    mkt_id: 144381199,
                    bet_ref: "NDY4ODI2ODcyOjE1LzQ6OjA",
                    prc_den: 4,
                    prc_num: 15,
                    seln_id: 468826872,
                    price_id: 448751188,
                    prob_100: 0.15724,
                    price_type: "LP",
                    prc_decimal: "4.75",
                    prc_american: "+375",
                    prc_fractional: "15/4",
                  },
                ],
              },
            },
            {
              selectionID: 468826871,
              selectionData: {
                mkt_id: 144381199,
                prices: [
                  {
                    mkt_id: 144381199,
                    bet_ref: "NDY4ODI2ODcxOjE4LzE6OjA",
                    prc_den: 1,
                    prc_num: 18,
                    seln_id: 468826871,
                    price_id: 448751187,
                    prob_100: 0.029352,
                    price_type: "LP",
                    prc_decimal: "19.00",
                    prc_american: "+1000",
                    prc_fractional: "10/1",
                  },
                ],
                status: "A",
                seln_id: 468826871,
                settled: "N",
                displayed: "Y",
                disporder: 0,
                ptcpnt_id: 812356,
                seln_name: "TJ Slavoj Myto",
                seln_sort: "H",
                ptcpnt_type: "T",
                result_conf: "N",
                price_history: [
                  {
                    mkt_id: 144381199,
                    bet_ref: "NDY4ODI2ODcxOjE4LzE6OjA",
                    prc_den: 1,
                    prc_num: 18,
                    seln_id: 468826871,
                    price_id: 448751187,
                    prob_100: 0.031978,
                    price_type: "LP",
                    prc_decimal: "19.00",
                    prc_american: "+1000",
                    prc_fractional: "10/1",
                  },
                  {
                    mkt_id: 144381199,
                    bet_ref: "NDY4ODI2ODcxOjE4LzE6OjA",
                    prc_den: 1,
                    prc_num: 18,
                    seln_id: 468826871,
                    price_id: 448751187,
                    prob_100: 0.02959,
                    price_type: "LP",
                    prc_decimal: "19.00",
                    prc_american: "+1000",
                    prc_fractional: "10/1",
                  },
                  {
                    mkt_id: 144381199,
                    bet_ref: "NDY4ODI2ODcxOjIwLzE6OjA",
                    prc_den: 1,
                    prc_num: 20,
                    seln_id: 468826871,
                    price_id: 448751187,
                    prob_100: 0.028822,
                    price_type: "LP",
                    prc_decimal: "21.00",
                    prc_american: "+2000",
                    prc_fractional: "20/1",
                  },
                ],
              },
            },
          ],
          dividends: null,
        },
      ],
      eventMatch: { matchincidents: null, matchstats: null },
    },
  ];
};

import "./App.css";
import { useEffect, useState } from "react";
import Header from "./components/Header";
import LiveSection from "./components/LiveSection/LiveSection";
import ScoreSection from "./components/ScoreSection/ScoreSection";
import SportsTypesManu from "./components/SportsTypesManu/SportsTypesManu";
import westHamUnited from "./images/teams/westHamUnited.png";
import arsenal from "./images/teams/arsenal.png";
import MenuOverlay from "./components/MenuOverlay/MenuOverlay.jsx";
import Favorites from "./components/Favorites/Favorites.jsx";
import "./fonts/Poppins-Regular.ttf";
import getData from "./getData.js";

function App() {
  const [navbarOpen, setNavbarOpen] = useState(false);
  const [routh, setRouth] = useState(navigation.HOME);
  const [favorites, setFavorites] = useState();
  const [data, setData] = useState([]);
  useEffect(() => {
    console.log("fagsrhdtjf");
    const newData = getData();
    console.log(newData);
    setData(newData);
  }, []);

  useEffect(() => {
    console.log("hi");

    const newData = getData();
    console.log(newData);
    setData(newData);
    const favoritesFromLocalStorage = localStorage.getItem("favorites");
    if (
      favoritesFromLocalStorage == undefined ||
      favoritesFromLocalStorage == null
    ) {
      localStorage.setItem("favorites", JSON.stringify({ 19161: true }));
    }
    setFavorites(JSON.parse(favoritesFromLocalStorage));
  }, []);

  const triggerFavorite = (val) => {
    const snapshot = favorites;
    if (snapshot[val] == true) {
      snapshot[val] = false;
    } else {
      snapshot[val] = true;
    }
    localStorage.setItem("favorites", JSON.stringify(snapshot));
    setFavorites({ ...snapshot });
  };
  const triggerNavManu = () => {
    setNavbarOpen(!navbarOpen);
  };
  const currentRouth = () => {
    if (routh == navigation.FAVORITES)
      return (
        <Favorites
          triggerNavManu={triggerNavManu}
          setRouth={setRouth}
          favorites={favorites}
          triggerFavorite={triggerFavorite}
        />
      );
    else
      return (
        <>
          <Header triggerNavManu={triggerNavManu} setRouth={setRouth} />
          <SportsTypesManu />
          <LiveSection data={data} />
          {/* <ScoreSection games={data} /> */}
        </>
      );
  };

  return (
    <div className="App">
      {currentRouth()}
      <MenuOverlay
        triggerNavManu={triggerNavManu}
        setRouth={setRouth}
        isOpen={navbarOpen}
        currentRouth={routh}
      />
    </div>
  );
}

export default App;

export const navigation = {
  HOME: "HOME",
  FAVORITES: "FAVORITES",
};

import championsLeague from "./images/leagues/championsLeague.png";
import ligue1 from "./images/leagues/ligue1.png";
import premierLeague from "./images/leagues/PremierLeague.png";
import laLiga from "./images/leagues/laLiga.png";
import serieA from "./images/leagues/SerieA.png";
import bundesliga from "./images/leagues/bundesliga.png";
export default {
  19161: {
    name: "Champions League",
    country: "Europe",
    logo: championsLeague,
  },
  19157: {
    name: "Premier League",
    country: "England",
    logo: premierLeague,
  },
  19160: {
    name: "La Liga",
    country: "Spain",
    logo: laLiga,
  },
  19159: {
    name: "Serie A",
    country: "Italy",
    logo: serieA,
  },
  19158: {
    name: "Bundesliga",
    country: "Germany",
    logo: bundesliga,
  },
  19327: {
    name: "Ligue 1",
    country: "FRANCE",
    logo: ligue1,
  },
};
export const CHAMPIONS_LEAGUE_ID = 19161;
export const PREMIER_LEAGUE_ID = 19157;
export const LIGUE_1_ID = 19327;
export const LA_LIGA_ID = 19160;
export const SERIA_A_ID = 19159;
export const BUNDESLIGA_ID = 19158;

import logo from "../../images/logo.png";
import backButton from "../../images/backButton.png";
import { navigation } from "../../App";
function HeaderManuOverlay({ triggerNavManu, setRouth }) {
  return (
    <div
      style={{
        paddingTop: "30px",
        paddingBottom: "30px",
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <img
        src={backButton}
        onClick={triggerNavManu}
        alt="back"
        style={{
          width: "16px",
          height: "14px",
          marginLeft: 20,
          cursor: "pointer",
        }}
      />
      <img
        src={logo}
        alt="logo"
        style={{ width: "106px", cursor: "pointer" }}
        onClick={() => {
          setRouth(navigation.HOME);
          triggerNavManu();
        }}
      />
      <div style={{ width: "14px", height: "16px", marginLeft: 20 }} />
    </div>
  );
}

export default HeaderManuOverlay;

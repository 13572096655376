import React from "react";
import TeamLogo from "./TeamLogo/TeamLogo";

const LiveGame = ({
  leagueName,
  leagueLogo,
  homeTeamLogo,
  homeTeamName,
  homeTeamScore,
  awayTeamLogo,
  awayTeamName,
  awayTeamScore,
}) => {
  return (
    <div
      style={{
        width: 374,
        height: 208,
        backgroundColor: "#43424C",
        borderRadius: "10px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginLeft: 20,
          marginTop: 20,
        }}
      >
        <img
          src={leagueLogo}
          alt={leagueName}
          style={{ width: 20, height: 20 }}
        />
        <span style={{ marginLeft: 10, color: "#9FA3B7" }}>{leagueName}</span>{" "}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          height: "74px",
          alignItems: "center",
        }}
      >
        <TeamLogo logo={homeTeamLogo} name={homeTeamName} />
        <span style={{ fontSize: 25, fontWeight: "bold" }}>
          {homeTeamScore} - {awayTeamScore}
        </span>
        <TeamLogo logo={awayTeamLogo} name={awayTeamName} />
      </div>
      <div />
    </div>
  );
};

export default LiveGame;

import React, { useEffect, useState } from "react";
import backButton from "../../images/backButton.png";
import { navigation } from "../../App";
import whiteStar from "../../images/icons/whiteStar.png";
import fullPinkStar from "../../images/icons/fullPinkStar.png";
import championsLeague from "../../images/leagues/championsLeague.png";

import leageMap, {
  BUNDESLIGA_ID,
  CHAMPIONS_LEAGUE_ID,
  LA_LIGA_ID,
  LIGUE_1_ID,
  PREMIER_LEAGUE_ID,
  SERIA_A_ID,
} from "../../leageMap";
const Favorites = ({
  triggerNavManu,
  setRouth,
  favorites,
  triggerFavorite,
}) => {
  const [currentFavorites, setCurrentFavorites] = useState(favorites);
  useEffect(() => {
    setCurrentFavorites(favorites);
  }, [favorites]);

  return (
    <div
      style={{
        paddingTop: "30px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "start",
        color: "white",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "start",
          justifyContent: "start",
          width: 374,
        }}
      >
        <img
          src={backButton}
          onClick={() => {
            setRouth(navigation.HOME);
          }}
          alt="back"
          style={{
            width: 16,
            height: 14,
            cursor: "pointer",
            marginRight: 30,
          }}
        />
        <div
          style={{
            alignItems: "start",
            textAlign: "start",
            color: "white",
            fontSize: 18,
            fontWeight: "bold",
          }}
        >
          Select Your Favorite Leagues
        </div>
      </div>
      <div
        style={{
          width: "374px",
          alignItems: "start",
          textAlign: "start",
          color: "#9FA3B7",
          display: "flex",
          marginTop: 10,
          fontSize: 12,
        }}
      >
        <span style={{ marginRight: 46 }}></span>
        <span>
          Customize your live sports experience by choosing your preferred
          leagues below
        </span>
      </div>
      <Leage
        name={leageMap[CHAMPIONS_LEAGUE_ID].name}
        countryName={leageMap[CHAMPIONS_LEAGUE_ID].country}
        logo={leageMap[CHAMPIONS_LEAGUE_ID].logo}
        isSelected={currentFavorites[CHAMPIONS_LEAGUE_ID] == true}
        onClick={() => {
          triggerFavorite(CHAMPIONS_LEAGUE_ID);
        }}
      />
      <Leage
        name={leageMap[PREMIER_LEAGUE_ID].name}
        countryName={leageMap[PREMIER_LEAGUE_ID].country}
        logo={leageMap[PREMIER_LEAGUE_ID].logo}
        isSelected={currentFavorites[PREMIER_LEAGUE_ID] == true}
        onClick={() => {
          triggerFavorite(PREMIER_LEAGUE_ID);
        }}
      />
      <Leage
        name={leageMap[LA_LIGA_ID].name}
        countryName={leageMap[LA_LIGA_ID].country}
        logo={leageMap[LA_LIGA_ID].logo}
        isSelected={currentFavorites[LA_LIGA_ID] == true}
        onClick={() => {
          triggerFavorite(LA_LIGA_ID);
        }}
      />
      <Leage
        name={leageMap[SERIA_A_ID].name}
        countryName={leageMap[SERIA_A_ID].country}
        logo={leageMap[SERIA_A_ID].logo}
        isSelected={currentFavorites[SERIA_A_ID] == true}
        onClick={() => {
          triggerFavorite(SERIA_A_ID);
        }}
      />
      <Leage
        name={leageMap[BUNDESLIGA_ID].name}
        countryName={leageMap[BUNDESLIGA_ID].country}
        logo={leageMap[BUNDESLIGA_ID].logo}
        isSelected={currentFavorites[BUNDESLIGA_ID] == true}
        onClick={() => {
          triggerFavorite(BUNDESLIGA_ID);
        }}
      />
      <Leage
        name={leageMap[LIGUE_1_ID].name}
        countryName={leageMap[LIGUE_1_ID].country}
        logo={leageMap[LIGUE_1_ID].logo}
        isSelected={currentFavorites[LIGUE_1_ID] == true}
        onClick={() => {
          triggerFavorite(LIGUE_1_ID);
        }}
      />

      {/* <Leage
        name={"Europe League"}
        countryName="Europe"
        logo={championsLeague}
        isSelected={false}
        onClick={() => {}}
      /> */}
    </div>
  );
};
const Leage = ({ logo, name, countryName, isSelected, onClick }) => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: 374,
      height: 80,
      borderBottom: "#43424C 0.1px solid",
    }}
  >
    <img
      src={logo}
      alt={name}
      style={{ width: 20, height: 20, marginRight: 5 }}
    />
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "start" }}
    >
      <div style={{ color: "#9FA3B7", fontSize: 12, marginBottom: 2 }}>
        {countryName}
      </div>
      <div style={{ fontSize: 14 }}>{name}</div>
    </div>
    {[...Array(25).keys()].map((ele) => (
      <div key={ele} />
    ))}
    <img
      src={isSelected ? fullPinkStar : whiteStar}
      alt={isSelected ? "fullPinkStar" : "whiteStar"}
      onClick={onClick}
      style={{ width: 20, height: 20, marginRight: 20, cursor: "pointer" }}
    />
  </div>
);
export default Favorites;

import logo from "../images/logo.png";
import hamburger_menu from "../images/hamburger_menu.png";
import { navigation } from "../App";
function Header({ triggerNavManu, setRouth }) {
  return (
    <div
      style={{
        paddingTop: "30px",
        paddingBottom: "30px",
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <img
        src={hamburger_menu}
        onClick={triggerNavManu}
        alt="logo"
        style={{
          width: "16px",
          height: "14px",
          marginLeft: 20,
          cursor: "pointer",
        }}
      />
      <img
        src={logo}
        alt="logo"
        style={{ width: "106px", cursor: "pointer" }}
        onClick={() => {
          setRouth(navigation.HOME);
        }}
      />
      <div style={{ width: "14px", height: "16px", marginLeft: 20 }} />
    </div>
  );
}

export default Header;

import React from "react";
import HeaderManuOverlay from "./HeaderManuOverlay";
import whiteStar from "../../images/icons/whiteStar.png";
import pinkstar from "../../images/icons/pinkstar.png";
import privacyPolicy from "../../images/icons/privacyPolicy.png";
import termsAndConditions from "../../images/icons/termsAndConditions.png";
import support from "../../images/icons/support.png";
import { navigation } from "../../App";
const MenuOverlay = ({ isOpen, triggerNavManu, setRouth, currentRouth }) => {
  return (
    <div
      className={isOpen ? "show_side_bar side_bar" : "side_bar"}
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        color: "white",
        zIndex: 99,
        backgroundColor: "#1A1925",
      }}
    >
      <HeaderManuOverlay triggerNavManu={triggerNavManu} setRouth={setRouth} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          height: "90%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Link
            logo={currentRouth == navigation.FAVORITES ? pinkstar : whiteStar}
            name={"Favorites Leagues"}
            isSelected={currentRouth == navigation.FAVORITES}
            onClick={() => {
              setRouth(navigation.FAVORITES);
              triggerNavManu();
            }}
          />
          <Link logo={privacyPolicy} name={"Privacy Policy"} />
          <Link logo={termsAndConditions} name={"Terms & Conditions"} />
        </div>
        <Link logo={support} name={"Support"} isBorderTop={true} />
      </div>
    </div>
  );
};

const Link = ({ logo, name, isSelected, isBorderTop, onClick }) => (
  <div
    onClick={onClick}
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: 374,
      height: 80,
      borderBottom: isBorderTop ? "" : "white 1px solid",
      borderTop: isBorderTop ? "white 1px solid" : "",
      cursor: "pointer",
    }}
  >
    <img
      src={logo}
      alt={name}
      style={{ width: 20, height: 20, marginRight: 20 }}
    />
    <div style={{ color: isSelected ? "#FF2882" : "white" }}>{name}</div>
    {[...Array(20).keys()].map((ele) => (
      <div key={ele} />
    ))}
  </div>
);
export default MenuOverlay;

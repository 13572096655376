import SportTypeLogo from "./SportTypeLogo/SportTypeLogo";
import football from "../../images/sportsTypes/football.png";
import baseball from "../../images/sportsTypes/baseball.png";
import basketball from "../../images/sportsTypes/basketball.png";
import tennis from "../../images/sportsTypes/tennis.png";
import volleyball from "../../images/sportsTypes/volleyball.png";

function SportsTypesManu() {
  return (
    <div style={{ display: "flex", flexDirection: "row", overflow: "scroll" }}>
      <SportTypeLogo isSelected={true} name="Football" logo={football} />
      <SportTypeLogo isSelected={false} name="Baseball" logo={baseball} />
      <SportTypeLogo isSelected={false} name="Basketball" logo={basketball} />
      <SportTypeLogo isSelected={false} name="Tennis" logo={tennis} />
      <SportTypeLogo isSelected={false} name="Volleyball" logo={volleyball} />
      <div style={{ width: 20, marginRight: 20 }} />
    </div>
  );
}

export default SportsTypesManu;

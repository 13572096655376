import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyBVXJK49UI670Zk03tmunPRW7AvTgGAfh0",
  authDomain: "wplay-live-scores.firebaseapp.com",
  projectId: "wplay-live-scores",
  storageBucket: "wplay-live-scores.appspot.com",
  messagingSenderId: "968865324877",
  appId: "1:968865324877:web:ba93c5098f9f6830506104",
  measurementId: "G-5B5BN8G3E5",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React from "react";
import RedPoint from "../../images/red-point.png";
import LiveGame from "./LiveGame/LiveGame";
import PremierLeague from "../../images/leagues/PremierLeague.png";
import nottinghamForest from "../../images/teams/nottingham-forest.png";
import manchesterUnited from "../../images/teams/manchesterUnited.png";
const LiveSection = ({ data }) => {
  console.log(data);
  return (
    <div
      style={{
        color: "white",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "20px",
      }}
    >
      <div
        style={{
          fontSize: 16,
          width: 374,
          display: "flex",
          marginBottom: "15px",
        }}
      >
        Live Now
        <img
          src={RedPoint}
          alt={"redPoint"}
          style={{ width: "4px", height: "4px" }}
        ></img>
      </div>
      {data.map((event) => (
        <LiveGame
          leagueLogo={PremierLeague}
          leagueName={"Premier League"} //TODO: set data here
          homeTeamLogo={nottinghamForest}
          homeTeamName={event.eventData.teams[0].team_name ?? ""}
          homeTeamScore={1}
          awayTeamLogo={manchesterUnited}
          awayTeamName={event.eventData.teams[1].team_name ?? ""}
          awayTeamScore={2}
        />
      ))}
    </div>
  );
};

export default LiveSection;
